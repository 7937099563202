<template>
  <div class="d-flex fill-height justify-center flex-column pb-2" style="width: 100%" v-if="currentUser.is_admin">
    <div style="width: 100%; height: 120px; border-bottom: 1px solid lightgrey; flex: none">
      <v-layout align-center justify-center column class="fill-height">
        <h1 class="d-flex">
          <v-icon color="#333333" size="40" class="mr-1">mdi-leaf</v-icon>
          Farm management
        </h1>
        <v-container fluid class="flex-fill overflow-y-hidden py-0" style="height: 40px; max-width: 1550px">
        </v-container>
      </v-layout>
    </div>
    <v-container fluid class="d-flex overflow-y-hidden flex-column" style="height: 100%; max-width: 1550px">
      <v-layout fill-height style="overflow-y: auto">
        <v-data-table
            height="100%"
            fixed-header
            style="height: 100%"
            :headers="userHeaders"
            :items="reports"
            :search="search"
            :loading="loading"
            :items-per-page="10"
            class="row-pointer order-table d-flex flex-column"
            hide-default-footer
            :server-items-length.sync="totalItem"
            loading-text="Loading... Please wait"
            :options.sync="options"
            :page.sync="page"
        >
          <template v-slot:top>
            <v-layout class="flex-wrap py-2">
              <v-layout>
                <FilterForm
                    :dateRanger.sync="filterForm.dateRanger"
                    :state.sync="filterForm.state_id"
                    :district.sync="filterForm.district_id"
                    :subDistrict.sync="filterForm.sub_district_id"
                    :type.sync="filterForm.survey_id"
                    :monitoringStatus.sync="filterForm.status"
                />
              </v-layout>
              <v-layout class="pt-2">
                <div style="flex: 5">
                </div>
                <div style="flex: 1" class="pl-4">
                  <v-text-field
                      class="ml-1"
                      outlined
                      dense
                      hide-details
                      v-model="recordFilter.search"
                      @input="debounceInput"
                      append-icon="mdi-magnify"
                      label="Search"
                  ></v-text-field>
                </div>
              </v-layout>
            </v-layout>
          </template>
          <template v-slot:[`item.roles`]="{ item }">
            <v-chip class="ma-1" small color="tab" style="width: 100px;" v-for="(role, index) in item.roles"
                    :key="index">{{ role.name }}
            </v-chip>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <!--            <v-btn icon min-height="0" min-width="0" width="30" height="30" color="green"-->
            <!--                   @click="() => $refs.updateFarmForm.openDialog(item)">-->
            <!--              <v-icon size="22">mdi-pencil</v-icon>-->
            <!--            </v-btn>-->
            <!--            <v-btn icon min-height="0" min-width="0" width="30" height="30" color="red" class="ml-2"-->
            <!--                   @click="() => $refs.deleteFarmForm.openDialog(item)">-->
            <!--              <v-icon size="21">mdi-delete-outline</v-icon>-->
            <!--            </v-btn>-->
            <v-btn icon min-height="0" min-width="0" width="30" height="30" color="green"
                   @click="() => $refs.recordDetail.openDialog(item)">
              <v-icon size="22">mdi-information-outline</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.active`]="{ item }">
            <v-checkbox
                @change="updateUser(item)"
                class="mt-0"
                hide-details
                color="primary"
                v-model="item.active"
            ></v-checkbox>
          </template>
        </v-data-table>
      </v-layout>
      <div class="text-center pa-3" style="flex: none; height: auto">
        <v-pagination :total-visible="5" v-model="page" :length="pageCount" id="task_pagination"></v-pagination>
      </div>
    </v-container>
    <PopupUpdateFarm :roles="roles" @getData="getData" ref="updateFarmForm"/>
    <PopupDeleteFarm @getData="getData" ref="deleteFarmForm"/>
  </div>
</template>

<script>
import {
  getAllUser, updateUser, getRole, getRecords
} from "@/backend";
import {mapState} from "@/store/ults";
import {debounce} from "lodash"
import PopupUpdateFarm from "./UpdateFarm"
import PopupDeleteFarm from "./DeleteFarm"

export default {
  components: {
    PopupUpdateFarm,
    PopupDeleteFarm
  },
  data() {
    return {
      options: {},
      status: true,
      page: 1,
      search: '',
      totalItem: 100,
      pageCount: 0,
      loading: false,
      roles: [],
      recordFilter: {
        status: 2,
        search: undefined,
      },
      filterForm: {
        dateRanger: this.getCurrentDate(),
        state_id: undefined,
        district_id: undefined,
        sub_district_id: undefined,
        survey_id: undefined
      },
      userHeaders: [
        {text: "Farmer Name", align: "left", sortable: true, value: "farmer_name",},
        {text: "Farmer Address", value: "farmer_address", sortable: true, align: "start"},
        {text: "State", value: "state", sortable: true, align: "start"},
        {text: "District", value: "district", sortable: false, align: "start"},
        {text: "Type", value: "type", sortable: false, align: "start"},
        {text: "Monitoring", value: "active", sortable: false, align: "start"},
        {text: "", value: "action", sortable: false, align: "end"}
      ],
      reports: []
    };
  },
  computed: {
    ...mapState("auth", ["currentUser"])
  },
  watch: {
    options: {
      handler() {
        this.getData()
      },
      deep: true
    },
    filterForm: {
      handler() {
        if (this.filterForm.dateRanger.length !== 2) return
        this.getData()
      },
      deep: true
    }
  },
  created() {
    if (!this.currentUser.is_admin) this.$router.push({path: '/app'})
    this.getRole()
  },
  methods: {
    async getRole() {
      this.loading = true
      try {
        const res = await getRole()
        this.roles = res.data.filter(role => role.id !== 1)
      } catch (e) {
        this.$store.commit('message/SHOW_ERROR', e.message)
      } finally {
        this.loading = false
      }
    },
    getCurrentDate() {
      let currentDate = new Date()
      let day = currentDate.getDate() < 10 ? '0' + currentDate.getDate() : currentDate.getDate()
      let month = currentDate.getMonth() < 10 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1)
      let year = currentDate.getFullYear()
      let beforeMonth = currentDate.getMonth() ? currentDate.getMonth() < 10 ? '0' + currentDate.getMonth() : currentDate.getMonth() : 12
      let beforeYear = currentDate.getMonth() ? year : year - 1
      return [beforeYear + '-' + beforeMonth + '-' + day, year + '-' + month + '-' + day]
    },
    getParamRecord() {
      let sort
      if (this.options.sortBy.length && this.options.sortBy.length) {
        if (this.options.sortDesc[0]) sort = this.options.sortBy[0]
        else sort = '-' + this.options.sortBy[0]
      }
      let param = {...JSON.parse(JSON.stringify(this.filterForm)), ...this.recordFilter}
      param['per_page'] = 10
      param['page'] = this.options.page
      param['sort'] = sort
      return param
    },
    debounceInput: debounce(function (e) {
      this.getData()
    }, 500),
    async getData() {
      try {
        this.reports = []
        this.loading = true
        const resRecord = await getRecords(this.getParamRecord())
        this.reports = resRecord.data.data
        this.totalItem = resRecord.data.total
        this.pageCount = resRecord.data.last_page
      } catch (e) {

      } finally {
        this.firstLoad = false
        this.loading = false
      }
    },
    async updateUser(user) {
      this.loading = true
      try {
        await updateUser({
          id: user.id,
          data: {
            "name": user.name,
            "phone": user.phone,
            "email": user.email,
            "role_ids": user.roles.map(role => role.id),
            "active": user.active
          }
        })
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
      } catch (e) {
        this.$store.commit('message/SHOW_ERROR', e.message)
      } finally {
        await this.getData()
        this.loading = false
      }
    }
  },
};
</script>
<style scoped>
/deep/
.v-chip__content {
  width: 180px !important;
}

/deep/
.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: inset 0px 4px 6px -5px rgb(50 50 50 / 75%),
  inset 0px -4px 8px -5px rgb(50 50 50 / 0%);
}

/deep/
.order-table th {
  background-color: var(--v-primary-base) !important;
  color: white !important;
}

/deep/
.list-image th {
  background-color: #6686a3 !important;
}

/deep/
.v-data-table__wrapper {
  border: 1px lightgrey solid;
  border-radius: 8px;
}
</style>
